@import "../../../../../static/scss/colours.scss";
.header {
    background-color: white;
    border-radius: 5px 5px 0 0;
    padding: 1rem 1rem 0 1rem;
    >.tabs {
        >ul {
            >li {
                >.tabContent {
                    align-items: center;
                    border-bottom: 1px solid $fasta-steel;
                    display: flex;
                    justify-content: center;
                    margin-bottom: -1px;
                    padding: .5em 1em;
                    vertical-align: top;
                    cursor: pointer;
                    &:hover {
                        border-bottom-color: $fasta-grey3;
                        color: $fasta-grey3;
                    }
                }
                &:global(.is-active) {
                    >.tabContent {
                        border-bottom-color: $fasta-tq;
                        color: $fasta-tq;
                    }
                }
            }
        }
    }
    >.close {
        right: 1rem;
        top: 1.5rem;
        cursor: pointer;
        position: absolute;
        display: inline;
        text-transform: uppercase;
        font-size: 0.8rem;
    }
}

.image-container {
    min-height: 600px;
}