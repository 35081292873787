@import "../../../static/scss/colours.scss";
.switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 21px;
    >input {
        &:checked+.slider {
            background-color: $fasta-tq;
            &:before {
                transform: translateX(20px);
            }
        }
        &:focus+.slider {
            box-shadow: 0 0 1px $fasta-tq;
        }
    }
    >.slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        transition: .4s;
        border-radius: 34px;
        &::before {
            border-radius: 50%;
            position: absolute;
            content: "";
            height: 13px;
            width: 13px;
            left: 3px;
            bottom: 4px;
            background-color: white;
            -webkit-transition: .4s;
            transition: .4s;
        }
    }
    >input {
        opacity: 0;
        width: 0;
        height: 0;
    }
}