@import "../../../../../../../static/scss/colours.scss";

.modalCard {
  width: 756px !important;
}

.cardHeader {
  background-color: #F7F7F9 !important;
  border-radius: 0px !important;
  >p span {
    font-weight: 500;
    font-size: 1.5rem;
  }
}

.contentContainer {
  background-color: #F7F7F9 !important;
  padding: 0px !important;
  width: 100% !important;
  height: 820px;
  border-radius: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  >div.flexContainer {
    height: 100%;
    width: 100%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  
    >div {
      height: calc(100% - 40px - 2rem);
      width: 100%;
      .filters {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        margin-bottom: 10px;
        width: 100%;
        >div {
          display: flex;
          justify-content: center;
          align-items: center;
          button {
            font-size: 1.3rem;
            padding: 0.5rem;
            border: none;
            background-color: transparent;
            color: $fasta-grey;
            cursor: pointer;
            &:focus {
              outline: none;
            }
          }
          .active {
            font-weight: 500;
            color: $fasta-black;
            border-bottom: 4px solid $fasta-tq;
          }
        }
      }
      .topicList {
        padding: 1rem;
        height: calc(100% - 45px);
        >div {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          div {
            font-size: 1.2rem;
            font-weight: 400;
            color: $fasta-grey2;
            &:first-child {
              grid-column: 1 / 3;
              padding: 0.3rem 1.5rem;
            }
            &:nth-child(2) {
              display: flex;
              justify-content: center;
            }
          }
        }
        >ul {
          overflow: auto;
          height: calc(100% - 40px);
          >li {
            height: 60px;
            font-size: 1.2rem;
            font-weight: 500;
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            &:nth-child(odd){
              background-color: white;
            }
            >div {
              display: flex;
              align-items: center;
              padding: 1rem;
            }
            .keyword {
              justify-content: flex-start;
              padding: 1rem 1.5rem;
              grid-column: 1 / 3;
            }
            .count {
              display: flex;
              justify-content: center;
            }
            .hideAndUnhide {
              justify-content: center;
              p {
                font-size: 1.2rem;
                font-weight: 400;
                border: none;
                background-color: transparent;
                cursor: pointer;
                &:focus {
                  outline: none;
                }
              }
              .hide {
                color: $fasta-black;
              }
              .unhide {
                color: $fasta-red;
              }
            }
          }
          >.placeholder {
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 1.5rem;
            color: #c4c4c4;
          } 
        }
      }
    }
    .buttons {
      height: calc(40px + 2rem);
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: flex-end;
      padding: 1rem;
      button {
        height: 40px;
        font-size: 1.2rem;
        font-weight: 500;
        border-radius: 3px;
        cursor: pointer;
        &:focus {
          outline: none;
        }
        &:first-child {
          width: 120px;
          color: $fasta-black;
          background-color: transparent;
          border: 1px solid $fasta-primary;
          margin-right: 15px;
        }
        &:nth-child(2) {
          width: 150px;
          color: white;
          background-color: $fasta-tq;
          border: none;
          &:hover {
            opacity: 0.9;
          }
        }
      }
    }
  }
}
