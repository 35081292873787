@import "../../../../../../../../static/scss/colours.scss";
.head {
    display: grid;
    grid-template-columns: min-content max-content auto;
    grid-template-rows: repeat(2, min-content);
    column-gap: 1rem;
    >div.author_image {
        grid-column: 1 / span 1;
        grid-row: 1 / span 2;
        display: grid;
        min-width: 2rem;
        >img,
        i {
            align-self: center;
            justify-self: center;
            font-size: 2rem;
            &:global(.fas) {
                color: $fasta-tq;
            }
        }
        >img {
            width: 2rem;
            height: 2rem;
            overflow: hidden;
            border-radius: 50%;
        }
    }
    >a.author_link {
        grid-column: 2 / span 2;
        grid-row: 1 / span 1;
        color: $fasta-darkblue2;
        font-size: 0.9rem;
        font-weight: 600;
    }
    >.time {
        grid-column: 2 / span 1;
        grid-row: 2 / span 1;
    }
    >.site {
        grid-column: 3 / span 1;
        grid-row: 2 / span 1;
        display: grid;
        grid-auto-flow: column;
        grid-auto-columns: max-content;
        column-gap: 5px;
        >img,
        p {
            align-self: center;
        }
        >img {
            height: 1rem;
        }
    }
    >.time,
    .site {
        font-size: 12px;
        color: $fasta-grey2;
    }
}