@import "../../../../../../static/scss/colours.scss";

.sites {
    background-color: white;
    >.header {
        display: grid;
        grid-auto-columns: max-content;
        border-bottom: 1px solid $fasta-pagebggrey;
        padding: 0.5rem;
        width: 100%;
        display: flex;
        justify-content: space-between;
        >h3 {
            font-weight: 600;
        }
    }
    >.chart {
        // padding: 0.5rem 3rem;
        height: 17.5rem;
        overflow-y: auto;
        overflow-x: hidden;
        .row {
            display: grid;
            width: 100%;
            padding: 0.5rem 1rem 0.5rem 1.5rem;
            grid-template-columns:10rem 5rem 10fr 1fr;
            column-gap: 1rem;
            >* {
                align-self: center;
                color: $fasta-grey2;
                text-align: center;
                cursor: initial;
            }
            >.site {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                text-align: left;
                >.logo {
                    height: 20px;
                    margin-right: 0.5rem;
                }
            }
            >p >span{
                display: block;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                max-width: 80px;
            }
            >.bar {
                >progress::-webkit-progress-value {
                    background: $fasta-tq;
                }
            }
            >:last-child {
                justify-self: right;
            }
        }
    }
}

#dropdownContainer {
    padding-top: 2px;
    i {
        display: block;
        margin-top: 4px;
    }
    // #dropdownTriggerContainer {
    //     width: 240px;
    // }
}

#dropdownButton {
    font-weight: 600;
    border: none;
    outline:none;
    width: 100%;
    height: 20px;
    display: flex;
    justify-content: space-between;
    &:focus {
        border: none;
        outline:none;
        box-shadow: none;
    }
    .chosen {
        text-transform: capitalize;
    }
}

.dropdownOption {
    cursor: pointer;
    &:hover {
        background-color: #f5f5f5;
    }
}