@import "../../../../../../../../static/scss/colours.scss";
@import "../../../../../../../../static/scss/include-media.scss";
.content {
    margin-bottom: 0.5rem;
    >.title {
        display: grid;
        grid-auto-flow: column;
        grid-auto-columns: max-content;
        column-gap: 5px;
        margin-bottom: 1rem;
        font-size: 0.8rem;
        >p {
            color: $fasta-grey2;
        }
        >a {
            color: $fasta-darkblue;
            align-self: center;
            @include media("<=tablet") {
                max-width: 200px;
            }
            font-weight: 600;
            /* These are technically the same, but use both */
            overflow-wrap: break-word;
            word-wrap: break-word;
            -ms-word-break: break-all;
            /* This is the dangerous one in WebKit, as it breaks things wherever */
            word-break: break-all;
            /* Instead use this non-standard one: */
            word-break: break-word;
            /* Adds a hyphen where the word breaks, if supported (No Blink) */
            -ms-hyphens: auto;
            -moz-hyphens: auto;
            -webkit-hyphens: auto;
            hyphens: auto;
            transition: all 0.2s ease;
            &:hover {
                text-decoration: underline;
                color: $fasta-oceanblue;
            }
        }
    }
    >.message {
        cursor: pointer;
        >p {
            color: $fasta-normaltext;
            word-wrap: break-word;
            white-space: pre-wrap;
            overflow-wrap: anywhere;
            -webkit-line-clamp: 2;
            @include media("<=tablet") {
                padding: 0 1rem;
                max-width: 70vw;
            }
            @include media("<=phone") {
                max-width: 60vw;
            }
        }
    }
    >span {
        color: $fasta-tq;
        cursor: pointer;
        display: block;
        &:hover {
            text-decoration: underline;
        }
    }
}