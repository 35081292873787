@import "../../../../../static/scss/colours.scss";
.loading {
    border: 0.5rem solid $fasta-lightgrey;
    border-radius: 50%;
    border-top: 0.5rem solid $fasta-tq;
    width: 15vmin;
    height: 15vmin;
    -webkit-animation: spin 2s linear infinite;
    /* Safari */
    animation: spin 2s linear infinite;
    justify-self: center;
    align-self: center;
    box-shadow: 0 0 10px $fasta-tq;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    50% {
        border-top-color: $fasta-red;
        box-shadow: 0 0 15px $fasta-red;
    }
    100% {
        transform: rotate(360deg);
    }
}