.loading {
    justify-self: center;
    align-self: center;
    text-align: center;
    >h3 {
        font-weight: 500;
    }
    >img {
        animation: loading-ani 3s linear infinite;
        border-radius: 100%;
        height: 250px;
        width: 250px;
    }
}

@keyframes loading-ani {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}