@import "../../../../../static/scss/colours.scss";
.qrybuilder {
    display: grid;
    grid-template-rows: repeat(2, min-content) min-content max-content max-content;
    column-gap: 1rem;
    >.names {
        display: inline-grid;
        grid-template-columns: repeat(4, max-content);
        column-gap: 1rem;
        >.sep,
        .orBtn {
            justify-self: center;
            align-self: center;
            margin-top: 0.5rem;
        }
    }
    >.recTxt {
        grid-row: 1 / span 1;
        padding: 0.5rem 0 0.8rem 0;
        >p {
            color: $fasta-grey2;
            >span {
                color: $fasta-tq;
                cursor: pointer;
            }
        }
    }
    >.qryHead {
        grid-row: 2 / span 1;
        border: 1px solid $fasta-lightgrey;
        border-bottom: none;
        display: grid;
        grid-template-columns: auto max-content;
        padding: 0.3rem 1rem;
        background-color: white;
        >p {
            color: $fasta-grey2;
            font-size: 0.85rem;
        }
        >.switchBtn {
            grid-column: 2 / span 1;
            cursor: pointer;
            text-decoration: underline;
            color: $fasta-tq;
        }
    }
    >.qryview {
        border: 1px solid $fasta-lightgrey;
        min-height: 15rem;
        overflow-y: auto;
        padding: 1rem;
        background-color: white;
    }

    >.qryExample {
        border: 1px solid $fasta-lightgrey;
        border-top: none;
        padding: 0.3rem 1rem;
        background-color: white;
        margin-bottom: 0.5rem;
        display: flex;
        justify-content: space-between;
        button {
            height: 24px;
            border: none;
            color: #516380;
            background-color:#F2F5FA;
            cursor: pointer;
            border-radius: 4px;
            &:hover {
                background-color: #EBEBEC;
            }
            &:focus {
                outline: none;
            }
        }
    }
}