@import "../../../../../static/scss/colours.scss";
@import "../../../../../static/scss/page.module.scss";
@import "../../../../../static/scss/styles.scss";
@import "../../../../../static/scss/include-media.scss";

.icon {
  color: $fasta-steel;
  font-size: 22px;
  cursor: pointer;
  &:hover{
    color: $fasta-steel2;
  }
}