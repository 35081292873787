@import "../../../../../../static/scss/colours.scss";
.lineGraph {
  border-radius: 3px;
  background-color: white;
  padding: 1rem;
  > .head {
    display: flex;
    justify-content: space-between;
    padding: 0 0 0.5rem 0;
    margin-bottom: 3px;
    > .dropdownContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      button {
        width: 8rem;
        border: none;
        &:focus {
          outline: none;
          box-shadow: none;
        }
        > .buttonContentWrapper {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }
      }
      .dropdownOption {
        cursor: pointer;
        &:hover {
          background-color: #f5f5f5;
        }
      }
    }
    > h3 {
      color: $fasta-primary;
      font-weight: 500;
    }
  }
  > .line {
    width: 100% !important;
  }
}

.custom-tooltip-task-container {
  font-size: 14px;
  font-weight: 300;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  font-weight: bold;
}

.recharts-legend-item-text {
  font-weight: 500;
  font-size: 14px;
}
