@import "../../../../../../static/scss/colours.scss";

#dropdownContainer {
    padding-top: 2px;
    i {
        display: block;
        margin-top: 4px;
    }
}

#dropdownButton {
    font-weight: 600;
    border: none;
    outline:none;
    width: 100%;
    height: 20px;
    display: flex;
    justify-content: space-between;
    &:focus {
        border: none;
        outline:none;
        box-shadow: none;
    }
    .chosen {
        text-transform: capitalize;
    }
}

.dropdownOption {
    cursor: pointer;
    &:hover {
        background-color: #f5f5f5;
    }
}