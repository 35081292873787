@import "../../../../../static/scss/colours.scss";
@import "../../../../../static/scss/useful.module.scss";
#filterbar {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0.1rem 0 0 0;
    margin-bottom: 0.8rem;
    & :global(button.button.active) {
        color: $fasta-tq;
        font-weight: 600;
        background-color: $fasta-lite-tq;
    }
    >.left {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        row-gap: 0.5rem;
        max-width: max-content;
        // min-width: 1180px;
    }
    >.right {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        width: 100%;
        max-width: max-content;
        column-gap: 1rem;
        >.sitesFilter {
            @include mHide;
        }
    }
}