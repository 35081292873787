@import "../../../../../static/scss/colours.scss";
@import "../../../../../static/scss/page.module.scss";
@import "../../../../../static/scss/styles.scss";
@import "../../../../../static/scss/include-media.scss";

.contentContainer {
  background-color: white;
  height: 320px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  >.heading{
    font-size: 1.8rem;
    font-weight: 600;
    margin-bottom: 1rem;
    >i {
      color: $fasta-tq;
      margin-right: 1rem;
    }
  }
  >.subHeading {
    font-size: 1.2rem;
    font-weight: 300;
    margin-bottom: 2.5rem;
  }
  >.confirmButton {
    background-color: transparent;
    border: 1px $fasta-tq solid;
    border-radius: 3px;
    color: $fasta-tq;
    font-size: 1.7rem;
    font-weight: 500;
    padding: 0.5rem 1.5rem;
    cursor: pointer;
    &:focus {
      outline: none !important;
    }
    &:hover {
      background-color: $fasta-pagebggrey;
    }
  }
}
