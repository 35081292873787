@import "../../../../../static/scss/colours.scss";
@import "../../../../../static/scss/useful.module.scss";
#comments {
    display: grid;
    overflow-y: auto;
    overflow-x: hidden;
    grid-template-columns: max-content auto max-content;
    grid-template-rows: repeat(2, min-content) auto;
    >#title {
        grid-row: 1 / span 1;
        grid-column: 1 / span 1;
        font-weight: 500;
        color: $fasta-grey2;
    }
    >#totalrecords {
        grid-row: 2 / span 1;
        grid-column: 1 / span 1;
        color: $fasta-darkgrey;
        font-size: 0.8rem;
    }
    >#sort {
        display: grid;
        grid-auto-flow: column;
        column-gap: 0.5rem;
        >* {
            align-self: center;
        }
        grid-row: 2 / span 1;
        grid-column: 3 / span 1;
        justify-self: right;
    }
    >#commentsList {
        grid-row: 3 / span 1;
        grid-column: 1 / span 3;
        overflow-y: auto;
        display: grid;
        row-gap: 1rem;
        grid-auto-flow: row;
        padding: 0.5rem;
        @include fixH;
    }
}