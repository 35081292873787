@import "../../../../../../static/scss/colours.scss";

.wordCloud {
  background-color: white;
  > .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid $fasta-pagebggrey;
    padding: 0.5rem;
    > h3 {
      font-weight: 600;
      margin-right: 5px;
    }
  }
}

.dropdownContainer {
  position: relative;
  display: inline-block;
  button {
    display: block;
    width: 1.3rem;
    height: 1.3rem;
    color: $fasta-steel2;
    background-color: transparent;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    &:focus {
      outline: none;
    }
    &:hover {
      background-color: $fasta-pagebggrey;
    }
  }
  .dropdownMenu {
    width: 270px;
    display: flex;
    position: absolute;
    top: 2rem;
    right: 0;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 4px;
    background-color: white;
    padding: 0.5rem 0;
    box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
      0 0 0 1px rgba(10, 10, 10, 0.02);
    z-index: 1;
    > .dropdownItem {
      color: $fasta-black;
      padding: 0.375rem 1rem;
      width: 100%;
      cursor: pointer;
      &:hover {
        background-color: #f5f5f5;
        transition: 0.1s;
      }
      .dropdownItemContent {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        .dropdownItemContentIcon {
          margin-right: 1rem;
        }
      }
    }
  }
}
