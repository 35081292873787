@import "../../../../static/scss/colours.scss";
:global(.modal-card-foot) {
    justify-content: flex-end !important;
}

.foot {
    // display: grid !important;
    // justify-self: right;
    // grid-auto-flow: column;
    // column-gap: 1rem;
    display: flex;
    align-items: center;
    column-gap: 1rem;

    >p {
        color: #868686;
    }

    & button {
        font-weight: 500;
        background-color: white !important;
        border: 1.5px solid $fasta-tq !important;
        color: $fasta-tq !important;
    }
    > :global(.is-loading > button) {
        padding: 0 3rem 0 1rem;
    }
}