@import "../../../../../../../../static/scss/colours.scss";
.reactions {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: min-content;
    column-gap: 1rem;
    >span {
        word-break: keep-all;
        white-space: nowrap;
        display: grid;
        grid-auto-flow: column;
        column-gap: 0.3rem;
        font-size: 0.7rem;
        >i {
            color: $fasta-lightgrey3;
            align-self: center;
        }
        >img {
            max-width: 1rem;
            max-height: 1rem;
        }
    }
    >.angry,
    .haha,
    .sad,
    .wow {
        >i {
            color: $fasta-yellow
        }
    }
    >.love>i {
        color: $fasta-red;
    }
}