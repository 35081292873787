@import "../../../../static/scss/include-media.scss";
@import "../../../../static/scss/colours.scss";
.card_modal {
    width: inherit !important;
    @include media("<tablet") {
        max-width: 100vw;
    }
    > :global(.modal-card-head) {
        background-color: $fasta-pagebggrey;
    }
    >.card_body {
        @include media(">=tablet") {
            width: 70vw;
            height: 70vh;
        }
        @include media("<tablet") {
            width: 100vw;
            height: 100vh;
        }
        display: grid;
        grid-template-rows: min-content auto;
        row-gap: 1rem;
        background-color: $fasta-pagebggrey;
    }
}