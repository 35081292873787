#empty {
    display: grid;
    grid-auto-flow: row;
    row-gap: 1rem;
    justify-self: center;
    align-self: center;
    >#icon {
        font-size: 2rem;
    }
    >* {
        justify-self: center;
    }
}