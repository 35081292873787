@import "../../../../../../static/scss/colours.scss";

.head {
    display: flex;
    justify-content: space-between;
    padding: 0 0 0.5rem 0;
    margin-bottom: 3px;
    > .dropdownContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        button {
            // width: 8rem;
            border: none;
            &:focus {
                outline: none;
                box-shadow: none;
            }
            > .buttonContentWrapper {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
            }
        }
        .dropdownOption {
            cursor: pointer;
            &:hover {
                background-color: #f5f5f5;
            }
        }
    }
    >div>h3 {
        color: $fasta-primary;
        font-weight: 500;
    }
}
