@import "../../../../../static/scss/colours.scss";
.post {
    display: grid;
    row-gap: 0.5rem;
    background-color: transparent;
    padding: 20px;
    &:nth-child(odd) {
        background-color: $fasta-pagebggrey;
    }
    >.head {
        display: inline-grid;
        grid-auto-flow: column;
        grid-auto-columns: max-content;
        column-gap: 0.5rem;
        >.authorName {
            color: black;
        }
        >.date {
            color: #bdbdbd;
            font-size: 0.7rem;
            align-self: center;
        }
        >.site {
            >img {
                height: 1rem;
            }
        }
    }
    >.thread {
        font-size: 0.8rem;
        display: inline-grid;
        grid-template-columns: repeat(2, max-content);
    }
    >.content {
        // width: 80vw;
        max-width: 34rem;
        &:hover {
            text-decoration: underline;
        }
        >p {
            color: $fasta-primary;
            max-width: 100%;
        }
    }
    >.showMore {
        color: $fasta-tq;
        transition: all 0.2s ease;
        cursor: pointer;
        &:hover {
            text-decoration: underline;
        }
    }
}