@import "../../../../../../static/scss/colours.scss";
.comment {
    display: grid;
    grid-template-columns: min-content minmax(min-content, 5rem) auto;
    grid-template-rows: repeat(2, min-content);
    column-gap: 0.5rem;
    background-color: $fasta-pagebggrey;
    padding: 1rem;
    border-radius: 10px;
    >.author_image {
        grid-column: 1 / span 1;
        grid-row: 1 / span 1;
        align-self: center;
        >img,
        i {
            align-self: center;
            justify-self: center;
            font-size: 2rem;
            color: $fasta-tq;
        }
        >img {
            border-radius: 50%;
            height: 2rem;
            width: 2rem;
        }
    }
    >.author_name {
        grid-column: 2 / span 1;
        grid-row: 1 / span 1;
        font-size: 0.9rem;
        align-self: center;
        font-weight: 600;
    }
    >.time {
        grid-column: 3 / span 1;
        grid-row: 1 / span 1;
        align-self: center;
        font-size: 0.8rem;
        color: $fasta-grey;
    }
    >.message {
        grid-column: 1 / span 3;
        grid-row: 2 / span 1;
        word-break: break-all;
        white-space: pre-wrap;
        word-wrap: break-word;
        max-width: 20rem;
        >a {
            color: $fasta-normaltext;
            transition: all 0.2s ease;
            &:hover {
                color: $fasta-royalblue;
                text-decoration: underline;
            }
        }
        >span {
            color: $fasta-tq;
            cursor: pointer;
            display: block;
            &:hover {
                text-decoration: underline;
            }
        }
    }
}