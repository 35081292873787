@import "../../../../../static/scss/colours.scss";
.tasktype {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: min-content auto;
    column-gap: 4rem;
    row-gap: 3rem;
    padding: 3rem;
    >.type {
        width: 240px;
        position: relative;
        grid-row: 2 / span 1;
        padding: 1rem;
        cursor: pointer;
        box-shadow: 0 0 14px 2px $fasta-lightgrey;
        &:nth-child(3) {
            justify-self: flex-end;
        }
        &:hover,
        &:global(.active) {
            >span>span.circle>span.bgcircle {
                background-color: opac($fasta-hover-tq, 0.3);
            }
        }
        >span {
            display: grid;
            grid-auto-flow: row;
            grid-template-rows: 120px min-content;
            row-gap: 0.5rem;
            >span.circle {
                display: grid;
                justify-self: center;
                align-self: center;
                position: relative;
                >span.bgcircle {
                    display: inline-block;
                    position: absolute;
                    height: 100px;
                    width: 100px;
                    left: 10px;
                    border-radius: 50%;
                    background-color: $fasta-lightgrey;
                    transition: all 0.2s ease;
                }
                >img {
                    display: inline-block;
                    z-index: 1;
                    position: relative;
                    left: 20px;
                    height: 100px;
                    width: 120px;
                }
            }
            >p {
                justify-self: center;
                align-self: baseline;
                font-weight: 500;
                color: $fasta-primary;
            }

            >div {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                column-gap: 4px;
                >p {
                    justify-self: center;
                    align-self: baseline;
                    font-weight: 500;
                    color: $fasta-primary;
                }
            }
        }
    }
}

.disable {
    cursor: default !important;
    color: #C4C4C4;
    &:hover {
        >span>span.circle>span.bgcircle {
            background-color: $fasta-lightgrey !important;
        }
    }
}