@import "../../../../../static/scss/colours.scss";
.sources {
    display: grid;
    padding: 1rem;
    row-gap: 1rem;
    >.lang {
        display: inline-grid;
        column-gap: 1rem;
        grid-template-columns: repeat(2, max-content);
        >* {
            align-self: center;
        }
        >label {
            font-weight: 500;
        }
    }
    >p {
        font-weight: 500;
    }
    >.mediumpicker {
        display: grid;
        row-gap: 0.5rem;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 1rem;
        border: 2px solid $fasta-darksnow;
        padding: 2rem;
        background-color: white;
        & input {
            margin: 0 0.5rem 0 0;
        }
        >.list {
            display: grid;
            grid-auto-flow: row;
            row-gap: 0.5rem;
            >.item {
                display: grid;
                grid-template-columns: auto min-content;
                grid-template-rows: repeat(2, min-content);
                >label {
                    justify-self: right;
                }
                >.dscrp {
                    grid-column: 1 / span 2;
                    color: $fasta-grey2;
                    font-size: 0.8rem;
                }
            }
        }
        >.header {
            display: grid;
            grid-template-columns: repeat(2, max-content);
            column-gap: 0.5rem;
            border-bottom: 1px solid $fasta-darksnow;
            font-size: 0.7rem;
            align-self: center;
            padding: 0.5rem 0;
            >* {
                align-self: center;
            }
            >h3 {
                font-weight: 500;
                font-size: 1rem;
            }
            >label {
                display: grid;
                grid-auto-flow: column;
                column-gap: 0.5rem;
                >* {
                    align-self: center;
                }
            }
        }
    }
}