@import "../../../static/scss/colours.scss";
#vnav {
    grid-row: 1 / span 2;
    grid-column: 1 / span 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    row-gap: 5rem;
    background: linear-gradient(#283044, #355a5e);
    padding: 1rem 0rem;
    >div>span {
        width: 3.5em;
        padding: 1em 0.5em;
        display: grid;
        cursor: pointer;
        transition: all 0.2s ease;
        border: 0;
        &.sub_items {
            &>i {
                border-radius: 50%;
                padding: 0.7em;
                color: white;
                transition: all 0.2s ease;
                font-size: 0.9rem;
                align-self: center;
                justify-self: center;
            }
            &:global(.active)>i {
                background: linear-gradient(to right, $fasta-tq 0%, $fasta-royalblue 100%);
                border-color: $fasta-tq;
            }
            &:hover>i {
                background-color: #ffffff31;
            }
        }
        >* {
            justify-self: center;
            align-self: center;
        }
    }
}