// MAIN COLOURS
$fasta-tq: #29d1d7;
$fasta-red: #ed5252;
$fasta-lightred: #ff496ad6;
$fasta-primary: #516380;
$fasta-black: #232830;
$fasta-steel: #bdbdbd;
$fasta-snow: #dadada;
$fasta-darksnow: #d3d2e0;
// YELLOW
$fasta-yellow:#ffd45d;
// GREY
$fasta-lightgrey: #e6e6e6d2;
$fasta-grey: #a1a1a1;
$fasta-darkgrey: #4c4c4c;
$fasta-darkgrey2: #4f4f4f;
$fasta-shadowgrey: #d3d2e063;
$fasta-pagebggrey: #f8f8fa;
$fasta-steel2: #dbdbdb;
$fasta-grey2: #828282;
$fasta-grey3: #363636;
$fasta-lightgrey3: #d8d8d8;
$fasta-disable: #92a5c3;
$fasta-lightgrey2:#ffffff70;
// BLACK
$fasta-normaltext: #252525c5;
// BLUE
$fasta-royalblue: #3a89ff;
$fasta-darkblue: #4d5280;
$fasta-oceanblue: #00acee;
$fasta-darkblue2:#283044;
// green
$fasta-green: #6BD086;
// sentiment
$fasta-sent-vpos:#6bd086;
$fasta-sent-pos: #97d06b;
$fasta-sent-nt: #ffcc4d;
$fasta-sent-ng: #ff3d00;
$fasta-sent-vng: #ff0000;
// lite
$fasta-lite-tq:#cef9fb;
$fasta-lite-blue:#effafb;
$fasta-lite-red: #ff6e86;
// hover
$fasta-hover-tq: #1bc2c8;
// add opacity to a given colour hex
@function opac($colour, $op) {
    @return rgba($colour, $op);
}