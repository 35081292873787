.headFilter {
  display: flex;
  align-items: center;
  > div {
    height: 33px;
  }
}

@import "../../../../../static/scss/colours.scss";

#dropdownContainer {
  padding-top: 2px;
  i {
    display: block;
    margin-top: 4px;
  }
}

#dropdownButton {
  font-weight: 600;
  border: none;
  outline: none;
  width: 100%;
  height: 20px;
  display: flex;
  justify-content: space-between;
  background-color: transparent !important;
  padding-left: 5px;
  text-align: left;
  &:focus {
    border: none;
    outline: none;
    box-shadow: none;
  }
  .chosen {
    text-transform: capitalize;
  }
}

.dropdownOption {
  cursor: pointer;
  &:hover {
    background-color: #f5f5f5;
  }
}


.date-filter-text-container{
  color: #29D1D7;
  font-size: 0.9rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  display: none;
  flex: 1 1 auto;
  .date-filter-text{
      width: 120px;
      height: 33px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #DBF7F8;
  }
  .date-filter-separator{
      background-color: #DBF7F8;
      height: 33px;
      display: flex;
      align-items: center;
      justify-content: center;
  }
}

.img {
  display: none;
  width: 150px;
  margin-bottom: 10px;
  margin-right: 8px;
}