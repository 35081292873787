@import "../../../../static/scss/colours.scss";
@import "../../../../static/scss/page.module.scss";
@import "../../../../static/scss/styles.scss";
@import "../../../../static/scss/include-media.scss";
#subnav {
    display: grid;
    grid-template-columns: 2fr 1fr min-content;
    grid-template-rows: repeat(2, min-content);
    column-gap: 1rem;
    row-gap: 1rem;
    background-color: $fasta-primary;
    @include page-padding;
    padding-bottom: 0;
    @include media("<=tablet") {
        grid-template-columns: repeat(3, 1fr);
    }
    >#taskdropdown {
        // grid-row: 1 / span 1;
        grid-column: 3 / span 1;
        justify-self: right;
        height: 30px;
        & :global(button.button) {
            @include input;
        }
        & a {
            font-size: 0.8rem;
            text-transform: uppercase;
        }
        > :global(#taskmenu) {
            > :global(.dropdown-content) {
                max-height: 50vh;
                overflow-y: auto;
            }
        }
    }
    >#pagination {
        display: grid;
        grid-row: 2 / span 1;
        grid-column: 1 / span 2;
        grid-auto-flow: column;
        grid-auto-columns: min-content;
        column-gap: 3rem;
        transition: all 0.2s ease;
        text-transform: uppercase;
        >span {
            padding-bottom: 0.2rem;
            color: $fasta-disable;
            cursor: pointer;
            &:hover,
            &:global(.active) {
                color: white;
                border-bottom: 3px solid white;
            }
            &:global(.disable) {
                color: $fasta-disable;
                opacity: 0.4;
                &:hover {
                    color: $fasta-disable;
                }
            }
        }
    }
}