.CalendarDay__selected_span {
  background: #c4f8fa;
  color: #a4a3a3;
  border: 1px solid #c4f8fa;
}

.CalendarDay__selected {
  background: #00c4ce;
  color: white;
}

.CalendarDay__selected:hover {
  background: #c4f8fa;
  color: #c4f8fa;
}

.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background: #c4f8fa;
}
