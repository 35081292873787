@import "../../../../static/scss/colours.scss";
@import "../../../../static/scss/useful.module.scss";
#tasklist {
    display: grid;
    row-gap: 1em;
    padding: 3rem;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: max-content auto;
    >.left {
        display: grid;
        justify-self: left;
        column-gap: 1rem;
        grid-auto-flow: column;
        grid-auto-columns: max-content;
        >h3 {
            font-weight: 500;
            font-size: 1.5rem;
        }
    }
    >.right {
        justify-self: right;
        display: grid;
        grid-auto-flow: column;
        grid-auto-columns: max-content;
        column-gap: 1rem;
        @include mHide;
        input[type=text]:focus {
            border-color: #b5b5b5;
            box-shadow: none;
        }
        button:focus{
            border-color: #b5b5b5;
            box-shadow: none;
        }
    }
}

.taskListFlexContainer {
    display: flex;
    flex-direction: column;
    grid-column: 1 / span 2;
    >.taskListTitleContainer { // title row
        display: grid;
        grid-template-columns: 5fr 2fr 2fr 2fr 1fr;
        margin-bottom: 0.5rem;
        padding: 0 1rem;
        min-width: 960px;
        >.taskListTitleItem {
            font-weight: 400;
            color: #868686;
            display: flex;
            justify-content: center;
            &:first-child {
                justify-content: flex-start;
            }
        }
    }
}