.headFilter{
    display: flex;
    align-items: center;
    >div {
        height: 33px;
    }
}

.date-filter-text-container{
    color: #29D1D7;
    font-size: 0.9rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    display: none;
    flex: 1 1 auto;
    .date-filter-text{
        width: 120px;
        height: 33px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #DBF7F8;
    }
    .date-filter-separator{
        background-color: #DBF7F8;
        height: 33px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.app-version-container{
font-size: 12px;
}

.task-name-container{
    font-size: 16px;
    display:none;
    margin-bottom: 10px;
    margin-left: 10px;

    .task-name{
        font-size: 20px;
        font-weight: bold;
        
    }

    .task-version-logo-container{
        display: flex;
        align-items: center;
    }
}

.img {
    display: block;
    width: 150px;
    margin-bottom: 10px;
    margin-right: 8px;
}