@import "../../../../../../static/scss/colours.scss";
@import "../../posts.module.scss";
@import "../../../../../../static/scss/useful.module.scss";

// .message {
//     display: grid;
//     width: 100%;
//     min-height: 15rem;
//     @include msgGrid-columns;
//     grid-template-rows: min-content auto;
//     border: 1px solid $fasta-lightgrey;
//     padding-bottom: 1rem;
//     border-radius: 5px;
//     background-color: white;
//     column-gap: 1rem;
//     row-gap: 0rem;
//     transition: all 0.2s ease;
//     &:hover {
//         box-shadow: 0 2px 5px 1px $fasta-shadowgrey;
//     }
//     >.bookmark {
//         justify-self: center;
//         height: 12px;
//     }
//     >.content {
//         display: grid;
//         grid-row: 2 / span 1;
//         grid-column: 1 / span 2;
//         padding: 0 2rem;
//         row-gap: 1rem;
//     }
//     >.sentiment,
//     .impact,
//     .virality {
//         @include mHide;
//         grid-row: 2 / span 1;
//         display: grid;
//     }
//     >.sentiment {
//         grid-column: 3 / span 1;
//     }
//     >.impact {
//         grid-column: 4 / span 1;
//     }
//     >.virality {
//         grid-column: 5 / span 1;
//     }
// }


.bookmark {
    justify-self: center;
    height: 12px;
    width: 100%;
}

.content {
    display: grid;
    grid-row: 2 / span 1;
    grid-column: 2 / span 1;
    margin-left: -2rem;
    row-gap: 1rem;
}

.sentiment,
.impact,
.virality {
    @include mHide;
    grid-row: 2 / span 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sentiment {
    grid-column: 3 / span 1;
}

.impact {
    grid-column: 4 / span 1;
}

.virality {
    grid-column: 5 / span 1;
}

@mixin calculating {
    row-gap: 0.2rem;
    color: $fasta-snow;
    >span {
        font-size: 1.5rem;
    }
}