@import "../../../../static/scss/colours.scss";
@import "../task.module.scss";
@import "../../../../static/scss/include-media.scss";
@import "../../../../static/scss/useful.module.scss";
#threadViewer {
    display: grid;
    grid-template-rows: repeat(3, min-content) max-content;
    row-gap: 1rem;
    z-index: 1;
    position: absolute;
    height: 100%;
    background-color: white;
    right: 0;
    transform: translateX(100%);
    box-shadow: 0 15px 15px opac($fasta-darkblue, 0.3);
    transition: all 0.2s ease;
    padding: 1.5rem;
    width: 500px;
    overflow-y: auto;
    @include fixH;
    @include media("<=tablet") {
        width: calc(100vw - 56px);
    }
    &:global(.show) {
        transform: translateX(0%);
    }
    >#gapLine {
        background-color: $fasta-lightgrey;
        border-radius: 15px;
        height: 2px;
        width: 100%;
    }
}