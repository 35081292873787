@import url("https://rsms.me/inter/inter.css");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+HK:wght@300;400;700&display=swap");
@import url("https://fonts.googleapis.com/earlyaccess/cwtexhei.css");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&display=swap");
@import "./colours.scss";
@import "./zh-fonts.scss";
@mixin numberFont {
	font-family: -apple-system, BlinkMacSystemFont, "Noto Sans", "Segoe UI",
		"Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
		"Helvetica Neue", sans-serif;
}

@mixin font0 {
	font-family: -apple-system, BlinkMacSystemFont, "Inter",
		"Source Han Sans TC Regular", "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
		"Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@mixin font1 {
	font-family: -apple-system, BlinkMacSystemFont, "Inter", "cwTexHei",
		"Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
		"Droid Sans", "Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@mixin codeFont {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace !important;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@mixin style0 {
	@include font0;
}

@mixin input {
	background-color: $fasta-shadowgrey;
	color: white;
	border: 0;
	height: 1.8em;
	padding: 0 1em;
	transition: all 0.2s ease;
}

@mixin input_drop {
	@include input;
}

@mixin input_in {
	@include input;
	&::placeholder {
		color: $fasta-lightgrey2;
	}
	&:focus,
	:active {
		color: $fasta-darkblue;
		background-color: white;
		box-shadow: 0 0 14px 1px opac($fasta-darkblue2, 0.3);
		&::placeholder {
			color: $fasta-darksnow;
		}
	}
}

@mixin _input_has_icon {
	& ~ span:global(.icon) {
		z-index: 0;
		height: 2.4em;
		transition: all 0.2s ease;
		font-size: 0.8em;
	}
}

@mixin input-icon {
	@include input_in;
	@include _input_has_icon;
	padding: 0 2em;
}

@mixin input-icon-left {
	@include input_in;
	@include _input_has_icon;
	padding: 0 1em 0 2em;
}

@mixin input-icon-right {
	@include input_in;
	@include _input_has_icon;
	padding: 0 2em 0 1em;
}
