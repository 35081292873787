@import "../../../../../static/scss/colours.scss";
@import "../../../../../static/scss/page.module.scss";
@import "../../../../../static/scss/styles.scss";
@import "../../../../../static/scss/include-media.scss";

.contentContainer {
  background-color: white;
  height: 230px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  >.heading{
    font-size: 1.8rem;
    font-weight: 600;
    margin-bottom: 1rem;
    color: $fasta-primary;
    >i {
      color: $fasta-red;
      margin-right: 0.5rem;
    }
  }
  >.subHeading {
    font-size: 1.2rem;
    font-weight: 300;
    margin-bottom: 2.5rem;
  }
  .options {
    display: flex;
    flex-direction: row;
    .cancelButton {
      background-color: transparent;
      border: none;
      border-radius: 3px;
      color: $fasta-primary;
      font-size: 1.4rem;
      font-weight: 500;
      padding: 0.5rem 1.5rem;
      margin-right: 2rem;
      margin-bottom: 1rem;
      cursor: pointer;
      &:focus {
        outline: none !important;
      }
      &:hover {
        background-color: $fasta-lightgrey;
      }
    }
    .tryAgainButton {
      background-color: $fasta-red;
      border: none;
      border-radius: 3px;
      color: white;
      font-size: 1.4rem;
      font-weight: 500;
      padding: 0.5rem 1.3rem;
      margin-bottom: 1rem;
      cursor: pointer;
      &:focus {
        outline: none !important;
      }
      &:hover {
        opacity: 0.9;
      }
    }
  }
}
