@import "../../../../../static/scss/colours.scss";
.settings {
    & h3 {
        font-weight: 500;
    }
    display: grid;
    row-gap: 1rem;
    >.daterange {
        >:global(.field) {
            align-items: center;
            >p:first-child {
                margin-right: 0;
            }
            >.sep {
                padding: 1rem;
            }
        }
        input {
            border-color: $fasta-steel2;
            &:focus {
                // border-color: #dbdbdb;
                box-shadow: none;
            }
            &:hover {
                border-color: #b5b5b5;
            }
        }
        .disabledDateInput {
            width: 196px;
            height: 40px;
            color: #868686;
            background-color: #F2F2F2;
            padding: 7px 11px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            border: 1px solid $fasta-steel2;
            border-radius: 4px;
            i {
                font-size: 1.1rem;
            }
        }
    }
    >.freq {
        display: grid;
        row-gap: 0.5rem;
        >.freqlist {
            display: inline-grid;
            grid-auto-flow: column;
            grid-auto-columns: max-content;
            column-gap: 1rem;
            >span {
                cursor: pointer;
                border-radius: 5px;
                padding: 0.3rem 1rem;
                border: 1px solid $fasta-steel2;
                transition: all 0.2s ease;
                &.disabled {
                    background-color: opac($fasta-lightgrey, 0.3);
                    color: $fasta-grey;
                    cursor: not-allowed;
                }
                &:hover {
                    background-color: #FFF;
                    border-color: #b5b5b5;
                }
                &.active {
                    color: white;
                    font-weight: 500;
                    background-color: $fasta-tq;
                    border: none;
                }
            }
        }
    }
}

.lang {
    column-gap: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
    >* {
        align-self: center;
    }
    >label {
        font-weight: 500;
        margin: 0px !important;
    }
    >#langSelect{
        margin-left: 1rem;
        margin-right: 0.5rem;
        &:not(.is-multiple):not(.is-loading)::after {
            border-color: $fasta-steel2;
            z-index: 0;
        }
    }
    >div select {
        border-color: $fasta-steel2;
        &:focus {
            border-color: $fasta-steel2;
            box-shadow: none;
        }
    }
    >i {
        color: $fasta-grey;
    }
}