.name {
    display: grid;
    grid-template-columns: 4fr 1fr;
    grid-template-rows: repeat(2, min-content) auto;
    column-gap: 1rem;
    >.title {
        grid-row: 1 / span 1;
        grid-column: 1 / span 2;
        font-weight: 500;
        font-size: 1.2rem;
    }
    >p.label {
        font-size: 0.7rem;
        font-weight: 400;
        grid-row: 2 / span 1;
        grid-column: 2 / span 1;
    }
    >input,
     :global(.field) {
        grid-row: 3 / span 1;
    }
}