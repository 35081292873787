@import "../../../static/scss/vars.module.scss";
$task-height: calc(100vh - #{$tnav-height} - 95px);
#task {
    display: grid;
    grid-template-rows: min-content $task-height;
    overflow-y: hidden;
    overflow-x: auto;
    >*:nth-child(n+2) {
        >*:last-child {
            margin-bottom: 1rem;
        }
    }
}