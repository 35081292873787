@import "../../static/scss/colours.scss";
#login {
    display: grid;
    max-width: 400px;
    margin: auto;
    border: 1px solid $fasta-lightgrey;
    border-radius: 5px;
    padding: 3rem 2rem;
    box-shadow: 0 0 15px 4px $fasta-shadowgrey;
    text-align: center;
    background-color: white;
    row-gap: 1rem;
    >h1 {
        font-size: 1.5rem;
    }
    & form button {
        background-color: $fasta-tq;
        transition: all 0.2s ease;
        &:hover {
            background-color: $fasta-hover-tq;
        }
    }
    & :global(.field:last-child > .control) {
        text-align: center;
    }
}

.img {
    margin: auto;
    display: block;
    padding: 1rem;
}

#login_page {
    background-color: $fasta-pagebggrey;
}