@import "../../../../../static/scss/colours.scss";

.taskCardGridContainer {
    height: 100px;
    min-width: 960px;
    display: grid;
    margin-bottom: 0.5rem;
    grid-template-columns: 5fr 2fr 2fr 2fr 1fr;
    background-color: white;
    padding: 1rem;
    border-radius: 5px;
    transition: all 0.2s ease;
    cursor: pointer;
    &:hover {
        box-shadow: 0 0 14px 3px rgba(211, 210, 224, 0.8); 
    }
    >.gridItem {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        font-weight: 600;
        p {
            color: #868686;
            font-size: 12px;
        }
        &:first-child{
            align-items: flex-start; 
        }
        >div:nth-child(1){
            display: flex;
            flex-direction: row;
        } 
        >div:nth-child(2){
            color: #868686;
            font-size: 14px;
            font-weight: 300;
            display: flex;
            flex-direction: row;
            >p:nth-child(1) {
                display: block;
                margin-right: 1rem;
                width: 150px;
                span {
                    font-weight: 500;
                }
            } 
            >p:nth-child(2) {
                display: block;
                font-weight: 200;
                font-size: 14px;
                .higher {
                    color: $fasta-tq;
                 
                }
                .lower {
                    color: $fasta-lite-red;
                }
                .percentageNumber {
                    font-weight: 400;
                }
            }
        }
    }
    .dropdownContainer {
        position: relative;
        display: inline-block;
        .dropdownButton {
            width: 2rem;
            height: 2rem;
            color: $fasta-grey;
            padding: 3px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            &:hover {
                background-color: $fasta-steel2;
            }
            .dropdownButtonIcon {
                display: inline-block;
            }
        }
        .dropdownMenu {
            width: 180px;
            display: flex;
            position: absolute;
            top: 2rem;
            flex-direction: column;
            align-items: flex-start;
            border-radius: 4px;
            background-color: white;
            padding: 0.5rem 0;
            box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
                0 0 0 1px rgba(10, 10, 10, 0.02);
            z-index: 1;
            > .dropdownItem {
                padding: 0.375rem 1rem;
                width: 100%;
                cursor: pointer;
                &:hover {
                    background-color: #f5f5f5;
                    >.dropdownItemContent {
                        >.dropdownItemContentIcon{
                            color: red;
                            transition: 0.1s;
                        }
                    }
                }
                .dropdownItemContent {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    .dropdownItemContentIcon {
                        margin-right: 1rem;
                    }
                }
            }
        }
    }
}