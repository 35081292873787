@import "../../../../../static/scss/colours.scss";
.loading_msg {
    display: grid;
    align-self: center;
    justify-self: center;
    row-gap: 1rem;
    width: 100%;
    & .wrapper {
        padding: 30px;
        background: #fff;
        width: 100%;
        display: grid;
    }
    & .wrapper-cell {
        display: grid;
        grid-template-columns: 5rem auto;
        row-gap: 1rem;
    }
    & .animated-background,
    .image,
    .text-line,
    .author-line {
        animation-duration: 1.25s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-name: placeHolderShimmer;
        animation-timing-function: linear;
        background: #F6F6F6;
        background: linear-gradient(to right, #F6F6F6 8%, #F0F0F0 18%, #F6F6F6 33%);
        background-size: 800px 104px;
        position: relative;
    }
    & .text-line {
        height: 10px;
        width: 30vw;
        margin: 10px 0;
        &:nth-child(odd) {
            width: 40vw;
        }
    }
    & .text {
        grid-column: 1 / span 2;
    }
    & .image {
        height: 3rem;
        width: 3rem;
        border-radius: 50%;
    }
    & .author-lines {
        display: grid;
        >.author-line:nth-child(odd) {
            width: 10vw;
            background: linear-gradient(to right, $fasta-snow 8%, $fasta-darksnow 18%, $fasta-snow 33%);
        }
        >.author-line {
            height: 10px;
            width: 8vw;
            bottom: 0;
        }
    }
}

@keyframes placeHolderShimmer {
    0% {
        background-position: -468px 0;
    }
    100% {
        background-position: 468px 0;
    }
}