@import "../../../../static/scss/styles.scss";
@import "../../../../static/scss/include-media.scss";
@import "../../../../static/scss/colours.scss";
#searcher {
    display: grid;
    justify-self: right;
    transition: all 0.3s ease;
    @include media(">tablet") {
        width: 15rem;
    }
    &:hover {
        width: 100%;
    }
    >input {
        @include input-icon;
    }
    >.reset {
        pointer-events: auto;
        cursor: pointer;
        &:hover {
            color: $fasta-red;
        }
    }
}