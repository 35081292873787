.ingroup {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    >div {
        margin: 0.2rem 0;
    }
    .sep {
        font-weight: 500;
        margin: 0px 5px;
        color: #29D1D7;
    }
}