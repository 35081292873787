@import "../../../../../../../static/scss/colours.scss";
.wrapper {
    display: inline-grid;
    grid-template-columns: repeat(2, max-content);
    >* {
        align-self: center;
    }
    .btn {
        display: inline-grid;
        font-size: 1rem;
        height: 1.5rem;
        width: 1.5rem;
        cursor: pointer;
        border-radius: 5px;
        color: white;
        justify-self: center;
        align-self: center;
        background-color: opac($fasta-hover-tq, 0.3);
        >i {
            justify-self: center;
            align-self: center;
        }
    }
    > :global(.dropdown) {
        &:global(.dropdown i) {
            transition: all 0.2s ease;
        }
        &:global(.dropdown.is-active i) {
            transform: rotate(-180deg);
        }
        > :global(.dropdown-menu[role=menu] .dropdown-item) {
            transition: all 0.2s ease;
            cursor: pointer;
            display: grid;
            grid-template-columns: max-content auto;
            column-gap: 0.5rem;
            &:hover {
                background-color: opac($fasta-tq, 0.3);
            }
            >.or,
            .ex {
                padding: 0 0.5rem;
                border-radius: 3px;
                color: white;
            }
            >.or {
                background-color: $fasta-tq;
            }
            >.ex {
                background-color: $fasta-lightred;
            }
        }
    }
}