@font-face {
    font-family: "Source Han Sans TC Regular";
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(https://d18dyb0v4b4cx9.cloudfront.net/font/SourceHanSansTC-Light.woff2) format('woff2');
}

@font-face {
    font-family: "Source Han Sans TC Regular";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://d18dyb0v4b4cx9.cloudfront.net/font/SourceHanSansTC-Regular.woff2) format('woff2');
}

@font-face {
    font-family: "Source Han Sans TC Regular";
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(https://d18dyb0v4b4cx9.cloudfront.net/font/SourceHanSansTC-Medium.woff2) format('woff2');
}

@font-face {
    font-family: "Source Han Sans TC Regular";
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(https://d18dyb0v4b4cx9.cloudfront.net/font/SourceHanSansTC-Bold.woff2) format('woff2');
}