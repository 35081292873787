@import "./vars.module.scss";
@import "./styles.scss";
:global(html) {
	overflow: hidden;
}

/*
:global(*) {
    @include font0;
}
*/

:global(body) {
	margin: 0;
}

:global(code) {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

.App {
	& :global(*):not(:global(.fas)):not(:global(.far)):not(:global(.fab)) {
		@include font0;
	}
	& > #main {
		height: 100vh;
		width: 100vw;
		overflow: hidden;
		display: grid;
		grid-template-rows: minmax(0, $tnav-height) auto;
		grid-template-columns: min-content auto;
		background-color: #f8f8fa;
		> .content {
			grid-row: 2 / span 1;
			grid-column: 2 / span 1;
			display: grid;
			overflow-x: hidden;
			overflow-y: auto;
		}
	}
}
