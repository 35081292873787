@import "../../../../../../static/scss/colours.scss";
.preview {
    display: grid;
    column-gap: 0.5rem;
    grid-template-columns: auto max-content;
    float: right;
    >.viewer {
        grid-column: 1 / span 1;
        grid-row: 1 /span 1;
        display: grid;
        border: 1px solid $fasta-primary;
        >.topbar {
            display: grid;
            background-color: $fasta-primary;
            grid-template-columns: max-content auto max-content;
            color: white;
            padding: 0.5rem 1rem;
            div:first-child {
                margin-right: 4px;
            }
            >.sort {
                display: grid;
                grid-template-columns: repeat(2, max-content);
                column-gap: 0.5rem;
            }
        }
        >.records {
            max-height: 30rem;
            overflow-y: auto;
            >.record {
                display: grid;
                padding: 0.5rem 1rem 1rem 1rem;
                &:nth-child(even) {
                    background-color: opac($fasta-shadowgrey, 0.3);
                }
                >.top {
                    display: grid;
                    grid-template-columns: repeat(2, max-content);
                    column-gap: 1rem;
                    >* {
                        align-self: center;
                    }
                    >.author {
                        font-weight: 500;
                    }
                    >.date {
                        color: opac($fasta-grey2, 0.6);
                        font-size: 0.8rem;
                    }
                }
                >.content {
                    display: text;
                    max-width: 50vw;
                    word-wrap: break-word;
                    word-break: break-all;
                    color: $fasta-primary;
                    // user-select: none;
                    >a.thread {
                        color: $fasta-primary;
                        font-weight: 600;
                    }
                }
            }
        }
    }
    >.prevBtn {
        grid-column: 2 / span 1;
    }
}