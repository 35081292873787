.Container.link-preview {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	width: fit-content;
	border: none;
	border-radius: 0;
	background-color: #f8f8fa !important;
}
.Container.link-preview > .Image {
	flex-grow: 5 !important;
	// min-width: 250px;
	// width: 250px;
}
.Container.link-preview > .LowerContainer {
	flex-grow: 5 !important;
	// max-width: 250px;
	// height: 250px;
	text-overflow: ellipsis;
}
.Container.link-preview > .LowerContainer > h3.Title {
}
.Container.link-preview > .LowerContainer > span.Description.Secondary {
}
.Container.link-preview > .LowerContainer > .Secondary.SiteDetails {
}
