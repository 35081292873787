.version-item-title {
  color: #516380;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
}

.version-item-active {
  color: #1877f2;
}

.version-item-current-version-text {
  font-size: 12px;
  line-height: 160%;
  color: #1877f2;
  font-style: italic;
}

.version-item-analytical-period {
  font-size: 12px;
  line-height: 160%;
  color: #898989;
  font-style: italic;
}
