@import "../../../static/scss/colours.scss";
#tnav {
	// grid-row: 1 / span 1;
	// grid-column: 2 / span 1;
	// grid-template-columns: auto min-content;
	// column-gap: 1rem;
	border-bottom: 1px solid $fasta-lightgrey;
	box-shadow: 1px 0px 3px $fasta-grey;
	display: flex;
	width: 100%;
	justify-content: flex-start;
	align-items: center;
	z-index: 2;
	> span {
		// display: grid;
		> * {
			padding: 0 12px;
			color: $fasta-normaltext;
			align-self: center;
		}
	}
}


.tnav-title{
	flex: 1 1 auto
}

.tnav-version{
	margin-right: 20px;
	font-size: 14px;
}


