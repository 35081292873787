@import "../../../../../static/scss/colours.scss";
#head {
    display: grid;
    grid-template-columns: auto min-content;
    column-gap: 2rem;
    grid-template-rows: repeat(3, min-content);
    row-gap: 0.5rem;
    >#title {
        grid-row: 1 / span 1;
        grid-column: 1 / span 1;
        font-weight: 600;
        font-size: 1rem;
    }
    >#close {
        grid-row: 1 / span 1;
        grid-column: 2 / span 1;
        cursor: pointer;
        font-size: 1.5rem;
        color: $fasta-darkblue;
    }
    >#timestamp {
        grid-row: 2 / span 1;
        color: $fasta-grey;
        font-size: 0.8rem;
        font-weight: 500;
    }
    >#link {
        grid-row: 3 / span 1;
        color: $fasta-oceanblue;
        >span {
            text-decoration: underline;
        }
    }
}