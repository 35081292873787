@import "../../../../../../static/scss/colours.scss";
.guided {
    display: flex;
    flex-wrap: wrap;
    height: 3.4rem;
    >div {
        margin: 0.2rem;
    }
    >.sep {
        display: flex;
        padding: 1rem;
        color: $fasta-tq;
        align-items: center;
    }
    >.ex {
        display: block;
        width: 100%;
    }
}