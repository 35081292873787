.loading {
    justify-self: center;
    align-self: center;
    color: #5a5a5a;
    height: 5rem;
    width: 5rem;
    position: relative;
    border: 4px solid;
    display: inline-block;
    animation: rect-rotate 1s linear infinite;
    &::after {
        content: '';
        height: 0;
        width: 4.5rem;
        display: block;
        background: #29d1d7;
        animation: fill-rect 1s linear infinite;
    }
}

@keyframes rect-rotate {
    0% {
        transform: rotate(0);
    }
    50%,
    100% {
        transform: rotate(180deg);
    }
}

@keyframes fill-rect {
    0% {
        filter: hue-rotate(0deg);
    }
    0%,
    50% {
        height: 0;
    }
    50% {
        filter: hue-rotate(180deg);
    }
    100% {
        height: 4.5rem;
        filter: hue-rotate(360deg);
    }
}