@import "../../../../../../static/scss/colours.scss";
@import "../../../../../../static/scss/include-media.scss";
.stats {
    display: grid;
    position: relative;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 1vw;
    @include media("<desktop") {
        grid-template-columns: repeat(3, 1fr);
    }
    @include media("<tablet") {
        grid-template-columns: repeat(2, 1fr);
    }
    >.block {
        display: grid;
        grid-template-columns: min-content max-content auto;
        grid-template-rows: auto repeat(2, min-content);
        background-color: white;
        padding: 0.5rem 1rem;
        border-radius: 3px;
        @include media("<tablet") {
            column-gap: 1vw;
        }
        >* {
            align-self: center;
        }
        >.icon {
            font-size: 1.2rem;
            @include media("<tablet") {
                font-size: 3vw;
            }
        }
        >.bigNum {
            text-align: center;
            font-weight: 600;
            font-size: 1.5rem;
            @include media("<tablet") {
                font-size: 4vw;
            }
        }
        >.text {
            grid-column: 1 / span 2;
            max-width: 7rem;
            color: $fasta-grey2;
            font-size: 0.8rem;
            @include media("<tablet") {
                font-size: 2vw;
            }
        }
        >.percent {
            display: grid;
            grid-auto-flow: column;
            grid-auto-columns: max-content;
            column-gap: 5px;
            color: $fasta-tq;
            font-size: 0.9rem;
            > :global(.fa-long-arrow-alt-down),
             :global(.fa-long-arrow-alt-down)+p {
                color: $fasta-lite-red;
            }
            @include media("<tablet") {
                font-size: 2.5vw;
                column-gap: 1px;
            }
            >* {
                align-self: center;
            }
        }
        >.info {
            grid-row: 3 / span 1;
            grid-column: 3 / span 1;
            justify-self: right;
            color: $fasta-steel;
            @include media("<tablet") {
                font-size: 2vw;
            }
        }
    }
}