@import "../../../../../static/scss/colours.scss";
.loading {
    align-self: center;
    justify-self: center;
    >.twin-circles {
        position: relative;
        align-items: center;
        justify-content: center;
        &:before,
        &:after {
            content: '';
            position: absolute;
            border-radius: 50%;
            width: 2rem;
            height: 2rem;
            background: $fasta-tq;
            top: calc(50% - 10px);
            left: calc(50% - 10px);
            animation: 2s ease-in-out infinite;
            animation-direction: alternate;
        }
        &:before {
            opacity: 0.7;
            animation-name: moveLeft;
        }
        &:after {
            opacity: 0.5;
            animation-name: moveRight;
        }
    }
}

@keyframes moveLeft {
    0% {
        background: $fasta-royalblue;
    }
    25% {
        transform: translatex(-50%);
    }
    50%,
    60% {
        transform: translatex(0);
    }
    100% {
        transform: translatex(50%);
        background: $fasta-tq;
    }
}

@keyframes moveRight {
    0% {
        background: $fasta-royalblue;
    }
    25% {
        transform: translatex(50%);
    }
    50%,
    60% {
        transform: translatex(0);
    }
    100% {
        transform: translatex(-50%);
        background: $fasta-tq;
    }
}