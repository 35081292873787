.dropdownTrigger {
  width: 230px;
  height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: solid #BCBCBC 1px;
  border-radius: 3px;
  padding: 0.05rem 0.5rem;
  cursor: pointer;
  &:hover {
    border: solid #92a5c3 1px;
  }
  .dropdownTriggerText {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: 100%;
  }
}


.dropdownMenu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 30vh;
  overflow: auto;
  padding: 0.5rem 0;
  width: 230px;
  position: absolute;
  background-color: white;
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
  0 0 0 1px rgba(10, 10, 10, 0.02);
  .dropdownItem {
    height: 30px;
    width: 100%;
    padding: 0.4rem 0.75rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    &:hover {
      background-color: #f5f5f5;
    }
    >input {
      margin-right: 10px;
      width: 1rem;
      height: 1rem;
    }
  }
}