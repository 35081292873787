@import "./include-media.scss";
$breakpoints: (phone: 640px, tablet: 768px, desktop: 1024px) !default;
// mobile hide
@mixin mHide {
    @include media("<=tablet") {
        display: none !important;
    }
}

// fix overflow y not being displayed
@mixin fixH {
    margin: 0 0 3rem 0;
}