@import "../../../../../static/scss/colours.scss";

$base-min-width: 160px;
$base-height: 35px;
$base-font-size: 0.9rem;

.container {
  position: relative;
}

.dropdownHeader {
  width: auto;
    button {
      font-size: $base-font-size;
      height: $base-height;
      min-width: $base-min-width;
      max-width: $base-min-width;
      color: $fasta-primary;
      background-color: white;
      border: 1px #dbdbdb solid;
      border-radius: 4px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-right: 15px;
      padding: 7px 12px;
      cursor: pointer;
      span {
        display: block;
        max-width: 150px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        &:nth-child(2){
          margin-left: 4px;
          min-width: 18px;
        }
      }
    &:focus {
      outline: none;
    }
    &:hover {
      border: 1px #b5b5b5 solid;
    }
  }
}

.dropdownMenu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: auto;
  padding: 0.5rem 0;
  min-width: $base-min-width;
  border-radius: 5px;
  position: absolute;
  background-color: white;
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
  0 0 0 1px rgba(10, 10, 10, 0.02);
  z-index: 11;
  .dropdownItem {
    font-size: $base-font-size;
    height: $base-height;
    width: 100%;
    padding: 0.4rem 1rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    &:hover {
      background-color: #f5f5f5;
    }
  }
}