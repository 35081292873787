@import "../../../../../static/scss/colours.scss";
.breadcrumb {
    display: inline-grid;
    justify-self: center;
    grid-auto-flow: column;
    grid-auto-columns: max-content;
    color: $fasta-steel;
    >span {
        text-align: center;
        cursor: pointer;
        >span {
            transition: all 0.2s ease;
            border-radius: 5px;
            padding: 0.3rem 0.5rem;
            font-weight: 500;
        }
        &.isClickable {
            color: $fasta-primary;
            &:hover {
                >span {
                    background-color: opac($fasta-hover-tq, 0.15);
                    color: $fasta-tq;
                }
            }
        }
        &.active {
            >span {
                background-color: opac($fasta-hover-tq, 0.15);
                color: $fasta-tq;
            }
        }
    }
    >span+span:before {
        padding: 1rem;
        color: $fasta-grey;
        content: "\203A";
    }
}