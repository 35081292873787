@import "../../../../static/scss/include-media.scss";
@import "../../../../static/scss/colours.scss";

@mixin msgGrid-columns {
    grid-template-columns: 1fr 12fr 4fr 4fr 4fr;
    @include media("<=tablet") {
        grid-auto-flow: row;
        grid-template-columns: auto;
    }
}


.empty-post-container{
    margin-top: -89px;
}